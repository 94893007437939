import { useGeo, useShoppingCart, useStores } from "@sushicorp/contexts";
import { useFetchCoordsFromGoogleAddress } from "@sushicorp/services";
import { useFetchGoogleAddressFromCoords } from "@sushicorp/services";
import { useFetchNearbyStores } from "@sushicorp/services";
import { isTouchScreenDevice } from "@sushicorp/utils";
import { defaultFunction, getMapSize } from "@sushicorp/utils";
import { getMaxWidth } from "@sushicorp/utils";
import { StaticMap, Clickable, Coordinates } from "artisn-ui-react";
import { Coords } from "google-map-react";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { flushSync } from "react-dom";

import Styles from "./AddressFormModal.styles";
import { AddressFormModalProps as Props } from "./AddressFormModal.types";
import { AddressFormValues } from "../AddressForm/AddressForm.types";
import ChooseAddressInMap from "components/chooseAddressInMap/ChooseAddressInMap/ChooseAddressInMap";
import Button from "components/global/Button/Button";
import { InfoShoppingCartModal } from "components/global/InfoShoppingCartModal/InfoShoppingCartModal";
import useInfoShoppingCartModal from "components/global/InfoShoppingCartModal/InfoShoppingCartModal.hooks";
import Modal from "components/global/Modal/Modal";
import SearchAddress from "components/global/SearchAddress/SearchAddress";
import AddressForm from "components/profileAddresses/AddressForm/AddressForm";
import MapAddressPreview from "components/profileAddresses/MapAddressPreview/MapAddressPreview";
import { getENVs } from "config/artisn.config";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useBenefit from "hooks/useBenefit/useBenefit";
import { useDeleteShoppingCart } from "hooks/useDeleteShoppingCart";
import useI18n from "hooks/useI18n";
import useValidateStoreGeo from "hooks/useValidateStoreGeo";
import useWindowSize from "hooks/useWindowSize";
import { Google } from "types/geo.types";
import { notify } from "utils/common.utils";
import { createErrorNotification } from "utils/notifications.utils";

import ChevronLeftSVG from "../../../../public/assets/images/chevron-left-black.svg";
import CloseSVG from "../../../../public/assets/images/close.svg";
import MapSVG from "../../../../public/assets/images/map.svg";
import EmptySVG from "../../../../public/assets/images/no-location.svg";

const { tablet } = CONSTANTS.BREAKPOINTS;

const AddressFormModal: React.FC<Props> = props => {
  const t = useI18n();
  const { opened, editAddress, locationOnly, onDismiss } = props;
  const { onClose = defaultFunction } = props;
  const [predictedPlaces, setPredictedPlaces] =
    useState<Google.Autocomplete[]>();
  const [initialValues, setInitialValues] = useState<AddressFormValues>();
  const [mapAddress, setMapAddress] = useState<Google.Geocode>();
  const { geometry } = mapAddress! ?? {};
  const { location } = geometry ?? {};
  const [coordinates, setCoordinates] = useState<Coordinates>(location);
  const [formattedAddress, setFormattedAddress] = useState("");
  const [socializedAddress, setSocializedAddress] = useState<
    string | undefined
  >();
  const auth = useAuth();
  const { isEmptyShoppingCart, emptyCartHandler } = useDeleteShoppingCart();
  const { isAnonymous } = auth;
  const [step, setStep] = useState(isAnonymous ? 3 : 1);
  const [prevStep, setPrevStep] = useState(isAnonymous ? 3 : 1);
  const { width, height } = useWindowSize();
  const { height: mapHeight } = getMapSize(width, height);
  const isMobile = width <= tablet;
  const maxWidth = +getMaxWidth().replace("px", "");
  const padding = isMobile ? 32 : 64;
  const isTouchDevice = typeof isTouchScreenDevice() === "boolean";
  const [selectedPlaceName, setSelectedPlaceName] = useState("");
  const [result, setResult] = useState("");
  const { data: place } = useFetchCoordsFromGoogleAddress(
    process.env.NEXT_PUBLIC_MAPS_API_KEY ?? "",
    selectedPlaceName
  );
  const { selectedCoordinates, deviceCoordinates } = useGeo();
  const { data: googleAddressFromCoords } = useFetchGoogleAddressFromCoords(
    process.env.NEXT_PUBLIC_MAPS_API_KEY ?? "",
    selectedCoordinates
  );
  const { setSelectedCoordinates } = useGeo();
  const { shoppingCart } = useShoppingCart();
  const { validateStoreGeo } = useValidateStoreGeo();
  const { setSelectedStore } = useStores();
  const { keepCartHandler } = useInfoShoppingCartModal();
  const { reApplyBenefit } = useBenefit();
  const { data: stores } = useFetchNearbyStores(
    coordinates ?? selectedCoordinates,
    notify
  );
  const { asPath } = useRouter();
  const noCoverage = !stores?.length;
  const isCheckout = asPath.includes("checkout");

  const handlerMapSearch = () => {
    setStep(3);
    setPrevStep(1);
  };

  const selectedCoordinatesHandler = (coordinates: Coords) => {
    setSelectedCoordinates(coordinates);
    setCoordinates(coordinates);
    setStep(2);
    onDismiss?.();
    if (locationOnly) {
      onClose();
      clearModal();
    }
  };

  const clickHandler = async (coordinates: Coords) => {
    if (isAnonymous && isCheckout) {
      return selectedCoordinatesHandler(coordinates);
    }
    if (!isEmptyShoppingCart) {
      try {
        const isConfirmed = await InfoShoppingCartModal({});
        if (typeof isConfirmed === "undefined") return;
        if (!isConfirmed) {
          const newStore = await validateStoreGeo(coordinates);
          const [benefit] = shoppingCart?.benefits ?? [];
          await keepCartHandler(coordinates);
          selectedCoordinatesHandler(coordinates);
          setSelectedStore(newStore);
          await reApplyBenefit(benefit);
          return;
        }
        emptyCartHandler();
        selectedCoordinatesHandler(coordinates);
      } catch (e) {
        console.error(e);
        createErrorNotification(e.message);
      }
      return;
    }
    selectedCoordinatesHandler(coordinates);
  };

  const addressInMapHandler = (
    address: Google.Geocode,
    socializedAddress?: string
  ) => {
    if (socializedAddress) setSocializedAddress(socializedAddress);
    setMapAddress(address);
    setStep(2);
  };

  const addressInMapGoBackHandler = () => {
    if (prevStep === 1) setStep(1);
    if (prevStep === 2) setStep(2);
  };

  const addressFormGoBackHandler = () => {
    setSocializedAddress(undefined);
    setSelectedPlaceName("");
    if (isAnonymous) setStep(3);
    else setStep(1);
  };

  const editAddressInMapHandler = () => {
    if (editAddress) {
      const { lat, lng } = editAddress;
      setPrevStep(2);
      setStep(3);
      setCoordinates({ lat, lng });
      return;
    }
    if (mapAddress) {
      setCoordinates(location);
      setPrevStep(2);
      setStep(3);
    }
  };

  const clearModal = () => {
    setStep(isAnonymous ? 3 : 1);
    setPrevStep(1);
    setInitialValues(undefined);
    setSelectedPlaceName("");
    setResult("");
    setPredictedPlaces(undefined);
  };

  useEffect(() => {
    if (opened && deviceCoordinates && !mapAddress && !editAddress) {
      setCoordinates(deviceCoordinates);
    }
  }, [opened, deviceCoordinates, isAnonymous, mapAddress, editAddress]);

  useEffect(() => {
    if (!editAddress) return;
    const { lat, lng, number: addressNumber, livingPlace } = editAddress ?? {};
    const { fields } = livingPlace;
    const { label } = fields[0];
    flushSync(() => {
      setCoordinates({ lat, lng });
      setFormattedAddress(label);
      setInitialValues({
        ...editAddress,
        addressNumber
      });
    });
    setStep(2);
    setPrevStep(2);
  }, [editAddress]);

  useEffect(() => {
    if (!mapAddress) return;
    const { geometry, formatted_address } = mapAddress;
    const { location } = geometry;
    setCoordinates(location);
    if (!socializedAddress) setSocializedAddress(selectedPlaceName);
    setFormattedAddress(formatted_address);
  }, [mapAddress, selectedPlaceName, socializedAddress]);

  useEffect(() => {
    if (!place) return;
    setMapAddress(place);
    setStep(2);
    setPrevStep(1);
  }, [place]);

  useEffect(() => {
    const [place] = googleAddressFromCoords ?? [];
    if (!place || !isAnonymous) return;
    setMapAddress(place);
    if (opened) return;
    setStep(3);
    setPrevStep(1);
  }, [googleAddressFromCoords, isAnonymous, opened]);

  const addressFormNode = (
    <div className="AddressFormModal__address-form">
      <h1 className="AddressFormModal__title AddressFormModal__title--fixed">
        {!editAddress ? (
          <Clickable
            className="AddressFormModal__icon AddressFormModal__go-back"
            onClick={addressFormGoBackHandler}
          >
            <ChevronLeftSVG />
          </Clickable>
        ) : null}
        {t.profile.address.fillAddress}
        <Clickable
          className="AddressFormModal__icon AddressFormModal__close"
          onClick={() => {
            onClose();
            clearModal();
          }}
        >
          <CloseSVG />
        </Clickable>
      </h1>
      <StaticMap
        googleMapsKey={`${getENVs?.mapsApiKey}`}
        width={isTouchDevice ? maxWidth - padding : 600 - padding}
        height={mapHeight}
        className="AddressFormModal__static-map"
        markers={[
          {
            coordinates,
            icon: "https://res.cloudinary.com/cbazcloud/image/upload/v1670966753/Map-point_Copy_5_x0xc5q.png"
          }
        ]}
      />
      {noCoverage ? (
        <p className="AddressFormModal__coverage">
          {t.profile.address.noCoverageDescription}
        </p>
      ) : null}
      <div className="AddressFormModal__header">
        <div className="AddressFormModal__info">
          <p className="AddressFormModal__info__title">
            {t.profile.address.currentPosition}
          </p>
          <p className="AddressFormModal__info__position">
            {socializedAddress?.length ? socializedAddress : formattedAddress}
          </p>
        </div>
        <Button
          type="BORDER"
          color="white"
          className="AddressFormModal__header__button"
          onClick={editAddressInMapHandler}
        >
          {t.profile.address.editLocation}
        </Button>
      </div>
      <AddressForm
        mapAddress={mapAddress!}
        method={editAddress ? "PUT" : "POST"}
        initialValues={initialValues}
        onPressButton={() => {
          onClose();
          clearModal();
        }}
        disabled={noCoverage}
        editAddress={editAddress}
      />
    </div>
  );

  const mapAddressPreviewNode = (
    <div className="AddressFormModal__map-preview">
      <h1 className="AddressFormModal__title AddressFormModal__title--fixed">
        {t.profile.navigation.deliveryAddress}
        <Clickable
          className="AddressFormModal__icon AddressFormModal__close"
          onClick={() => {
            onClose();
            clearModal();
          }}
        >
          <CloseSVG />
        </Clickable>
      </h1>
      <SearchAddress
        onPredictedPlaces={setPredictedPlaces}
        showSearchDropdown={false}
        onInputChange={() => setResult("")}
      />
      <div className="AddressFormModal__map-search" onClick={handlerMapSearch}>
        <MapSVG /> {t.profile.address.findAddress}
      </div>
      <div className="AddressFormModal__places">
        {predictedPlaces?.length === 0 ? (
          <div className="AddressFormModal__empty">
            <div className="AddressFormModal__empty__icon">
              <EmptySVG />
            </div>
            <div className="AddressFormModal__empty__title">
              {t.profile.address.noResultsFound}
            </div>
          </div>
        ) : null}
        {predictedPlaces?.map((item, index) => {
          const { description } = item;
          return (
            <MapAddressPreview
              key={index}
              address={description}
              onClick={() => setSelectedPlaceName(description)}
              className="AddressFormModal__map-address-preview"
            />
          );
        })}
        {result ? (
          <MapAddressPreview
            address={result}
            onClick={() => setSelectedPlaceName(result)}
            className="AddressFormModal__map-address-preview"
          />
        ) : null}
      </div>
    </div>
  );

  const chooseAddressInMapNode = (
    <div className="AddressFormModal__address-in-map">
      <h1 className="AddressFormModal__title">
        {!isAnonymous ? (
          <Clickable
            className="AddressFormModal__icon AddressFormModal__go-back"
            onClick={addressInMapGoBackHandler}
          >
            <ChevronLeftSVG />
          </Clickable>
        ) : null}
        {t.profile.address.findYourAddress}
        <Clickable
          className="AddressFormModal__icon AddressFormModal__close"
          onClick={() => {
            onClose();
            clearModal();
          }}
        >
          <CloseSVG />
        </Clickable>
      </h1>
      <ChooseAddressInMap
        onSelectedAddress={addressInMapHandler}
        initialCoordinates={coordinates}
        onSelectedCoordinates={clickHandler}
        socializedAddress={selectedPlaceName}
      />
    </div>
  );

  return (
    <Modal
      {...props}
      closeOnClickOutside={false}
      opened={opened}
      onClose={() => {
        onClose();
        clearModal();
      }}
    >
      <Styles className="AddressFormModal" step={step}>
        {mapAddressPreviewNode}
        {addressFormNode}
        {chooseAddressInMapNode}
      </Styles>
    </Modal>
  );
};

AddressFormModal.defaultProps = {};

export default AddressFormModal;
