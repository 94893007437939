import { TalkShopProvider as ITalkShopProvider } from "@sushicorp/services";
import { sanitizeQueryParams } from "@sushicorp/utils";
import { useRouter } from "next/router";
import React, { createContext, useEffect, useMemo, useState } from "react";

import { TalkShopProviderProps as Props } from "./talkShop.context.types";
import { TalkShopProviderValue } from "./talkShop.context.types";

// @ts-ignore
export const TalkShopContext = createContext<TalkShopProviderValue>({});

export const TalkShopProvider: React.FC<Props> = props => {
  const { query } = useRouter();
  const { provider, identifier } = sanitizeQueryParams(query);
  const [talkShopProvider, setTalkShopProvider] = useState<ITalkShopProvider>();
  const [talkShopIdentifier, setTalkShopIdentifier] = useState("");

  useEffect(() => {
    const storedProvider = localStorage.getItem("talkShopProvider");
    const storedIdentifier = localStorage.getItem("talkShopIdentifier");

    const updateLocalStorage = (provider: string, identifier: string) => {
      localStorage.setItem("talkShopProvider", provider);
      localStorage.setItem("talkShopIdentifier", identifier);
      setTalkShopProvider(provider as ITalkShopProvider);
      setTalkShopIdentifier(identifier);
    };

    if (storedIdentifier && storedProvider) {
      if (!provider || !identifier) {
        setTalkShopProvider(storedProvider as ITalkShopProvider);
        setTalkShopIdentifier(storedIdentifier);
        return;
      }
      if (storedIdentifier !== identifier || storedProvider !== provider) {
        updateLocalStorage(provider, identifier);
        return;
      }
    }

    if (provider && identifier) {
      updateLocalStorage(provider, identifier);
    }
  }, [provider, identifier]);

  const value: TalkShopProviderValue = useMemo(() => {
    return {
      talkShopProvider,
      talkShopIdentifier,
      setTalkShopIdentifier,
      setTalkShopProvider
    };
  }, [talkShopProvider, talkShopIdentifier]);

  return (
    <TalkShopContext.Provider value={value}>
      {props.children}
    </TalkShopContext.Provider>
  );
};
