// Shopping cart utility functions
import { buildArtisnHeaders } from "@sushicorp/services";
import { PackageCost, Vendor } from "@sushicorp/types";
import { DELIVERY_CATALOGUE } from "@sushicorp/utils";
import { getProductTotals } from "artisn/products";
import { applyBenefit, removeBenefit } from "artisn/shopping-cart";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { getShoppingCartTotal } from "artisn/shopping-cart";
import { validateShoppingCart } from "artisn/shopping-cart";
import { getShoppingCart } from "artisn/shopping-cart";
import { ShoppingCartTotals } from "artisn/shopping-cart";
import { AdditionalInfo, Benefit, Catalogue } from "artisn/types";
import { CartProduct, CartStore } from "artisn/types";
import { ShoppingCart, Store } from "artisn/types";
import dayjs from "dayjs";

import CONSTANTS from "config/constants";
import { BenefitValidationStatus } from "types/benefit.types";

const { ARTISN, API } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = ARTISN;
const { API_URL } = API;

/**
 * Gets the products in the cart by the given vendor identifier.
 *
 * @param {ShoppingCart} shoppingCart The shopping cart from we want to get the
 * products
 * @param {number} vendorId The vendor to filter the products
 * @returns {CartProduct[]} An array of CartProducts if there are any with the
 * given vendor or undefined if not
 */
export const getShoppingCartProductsByVendor = (
  shoppingCart: ShoppingCart | undefined,
  vendorId: Vendor["id"]
): CartProduct[] | undefined => {
  if (!shoppingCart) return;
  const { stores } = shoppingCart;
  const store = Object.values(stores).find(
    store => `${store.vendor.id}` === vendorId
  );
  if (!store) return;

  return getShoppingCartProducts({
    ...shoppingCart,
    stores: { [store.storeId]: store }
  });
};

/**
 * Gets the totals of the cart by the given vendor identifier.
 *
 * @param {ShoppingCart} shoppingCart The shopping cart from we want to get the
 * totals
 * @param {number} vendorId The vendor to filter the products
 * @returns {ShoppingCartTotals} A totals breakdown of the shopping cart filter
 * by the given vendor or undefined if none given
 */
export const getShoppingCartTotalByVendor = (
  shoppingCart: ShoppingCart | undefined,
  vendorId: Vendor["id"] | undefined
): ShoppingCartTotals | undefined => {
  if (!shoppingCart) return;
  const { stores } = shoppingCart;
  if (typeof vendorId === "undefined") {
    return getShoppingCartTotal(shoppingCart);
  }

  const store = Object.values(stores).find(
    store => `${store.vendor.id}` === vendorId
  );
  if (!store) return;

  return getShoppingCartTotal({
    ...shoppingCart,
    stores: { [store.storeId]: store }
  });
};

/**
 * Utility to validate the shopping cart and throws an error if the validation fails.
 *
 * @since 3.0.0
 * @param {number} lat Latitude.
 * @param {number} lng Longitude.
 * @param {boolean}isAnonymous If the user is anonymous.
 */
export const validateShoppingCartUtility = async (
  lat: number,
  lng: number,
  isAnonymous: boolean,
  uid: string
) => {
  const headers = await buildArtisnHeaders();
  const shoppingCartValidations = await validateShoppingCart(
    {
      apiURL: API_URL,
      latitude: lat,
      longitude: lng,
      anonymous: isAnonymous,
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      accountId: ACCOUNT_ID,
      customerId: uid
    },
    headers
  );

  const { products, stores } = shoppingCartValidations ?? {};
  stores?.forEach(store => {
    const { type } = store;
    switch (type) {
      case "IS_OPEN":
        throw new Error(
          "Tienda cerrada, intenta volver luego para realizar tus compras"
        );
      case "OUT_OF_COVERAGE":
        throw new Error(
          "Estás fuera de cobertura, intenta cambiar tu ubicación para continuar"
        );
      default:
        throw new Error("Ocurrió un error al validar el carrito");
    }
  });
  if (products?.length) {
    throw new Error("Error al validar los productos del carrito de compras");
  }
  await checkCartBenefitsAlerts(isAnonymous, uid);
};

export const checkCartBenefitsAlerts = async (
  isAnonymous: boolean,
  uid: string
) => {
  // Check alerts for benefits
  const shoppingCart = await getShoppingCart({
    shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
    anonymous: isAnonymous,
    accountId: ACCOUNT_ID,
    customerId: uid
  });
  const { benefits } = shoppingCart ?? {};
  if (benefits) {
    const benefitsHasAlerts = Object.values(benefits).some(benefit => {
      //@ts-ignore Property alerts is not typed in the sdk
      return benefit?.alerts && benefit?.alerts?.length !== 0;
    });

    if (benefitsHasAlerts) {
      throw new Error("Error al validar los beneficios del carrito de compras");
    }
  }
};

/**
 * Gets the total amount of the package costs
 *
 * @param {PackageCost[] | undefined} packageCosts Package Costs
 * @param {CartProduct<AdditionalInfo, AdditionalInfo>[]} products Products
 *
 * @returns {number | null} The total amount of the package costs
 */
export const getPackageTotalUtility = (
  packageCosts: PackageCost[] | undefined
) => {
  if (!packageCosts) return null;

  const [packageCost] = packageCosts;
  const { price = 0 } = packageCost ?? {};
  return +price;
};

/**
 * Gets the total amount of products
 *
 * @param {CartProduct<AdditionalInfo, AdditionalInfo>[]} products Products
 *
 * @returns {number | null} The total amount of products
 */
export const getProductsAmountUtility = (
  products: CartProduct<AdditionalInfo, AdditionalInfo>[]
) => {
  return products.reduce((total, product) => total + product.amount, 0);
};

/**
 * Utility to get the subtotal of the shoppingCart when benefit of type discount is applied
 *
 * @since 3.0.0
 * @param {ShoppingCart}shoppingCart
 * @returns {number | null}
 */
export const getProductsSubtotalUtility = (shoppingCart?: ShoppingCart) => {
  if (!shoppingCart) return null;
  const { benefits } = shoppingCart;

  if (!benefits) return null;

  if (!benefits.length) return null;

  const [benefit] = benefits;
  const { type } = benefit;

  if (type === "ALTER_DELIVERY" || type === "PRODUCT") return null;

  const products = getShoppingCartProducts(shoppingCart);
  const subtotal = products.reduce((prev, current) => {
    const { amount } = current;
    const { netPrice = 0 } = getProductTotals(current, amount);
    return prev + netPrice;
  }, 0);

  return subtotal;
};

/**
 * Get shopping cart shipping cost.
 *
 * @since 3.0.1
 * @param {ShoppingCart} shoppingCart Current shopping cart
 * @returns {number} Shopping cart shipping cost
 */
export const getCartShippingCost = (shoppingCart?: ShoppingCart) => {
  if (!shoppingCart) return undefined;
  const { shippingCost } = shoppingCart;
  if (!shippingCost) return undefined;
  // @ts-ignore TODO: add type to sdk
  const { total, discountNetPrice } = shippingCost;

  return total + Number(discountNetPrice ?? 0);
};

/**
 * Get discount value from shopping cart when a benefit is applied.
 *
 * @since 3.0.1
 * @param {ShoppingCart} shoppingCart Current shopping cart
 * @returns {number} Discount total
 */
export const getDiscountTotalUtility = (
  shoppingCart?: ShoppingCart
): number => {
  if (!shoppingCart) return 0;

  const { benefits } = shoppingCart;
  const [benefit] = benefits ?? [];
  if (!benefit) return 0;

  const subTotal = getProductsSubtotalUtility(shoppingCart);
  const shippingCostTotal = getCartShippingCost(shoppingCart);
  const { discountFixed, discountPercentage, type } = benefit;

  switch (type) {
    case "PRODUCT":
      return 0;
    case "ALTER_DELIVERY":
      return shippingCostTotal ?? 0;
    case "DISCOUNT_FIXED":
      return Number(discountFixed ?? 0);
    case "DISCOUNT_PERCENTAGE":
      return ((discountPercentage ?? 0) * (subTotal ?? 0)) / 100;
    default:
      return 0;
  }
};

/**
 * Utility to get the benefitId of the first benefit in the shopping cart.
 *
 * @since 3.0.0
 * @param {ShoppingCart} shoppingCart - This is the shopping cart object.
 * @returns {Benefit} The benefitId of the first benefit in the shopping cart.
 */
export const getBenefitOfShoppingCartUtility = (
  shoppingCart?: ShoppingCart<AdditionalInfo> | null
) => {
  const { benefits } = shoppingCart ?? {};
  if (benefits?.length) return benefits[0];
};

/**
 * Utility to return an array of stores from a shopping cart
 *
 * @since 3.0.0
 * @param {ShoppingCart} [shoppingCart] - ShoppingCart - The shopping cart object.
 * @returns {CartStore[]} An array of CartStore objects.
 */
export const getStoreListUtility = (
  shoppingCart?: ShoppingCart<AdditionalInfo> | null
): CartStore[] => {
  const { stores } = shoppingCart ?? {};
  if (stores) {
    return Object.values(stores);
  }
  return [];
};

export const getProductsUtility = (stores?: CartStore[]): CartProduct[] => {
  const shoppingCartProducts: CartProduct[] = [];

  if (stores) {
    stores.forEach(store => {
      const { products } = store;

      if (products) {
        Object.values(products).forEach(product => {
          shoppingCartProducts.push(product);
        });
      }
    });
  }

  return shoppingCartProducts;
};

/**
 * Handle to handle the removal of the benefit from the shopping cart.
 *
 * @since 3.0.0
 */
export const removeBenefitsUtility = async (
  store: Store,
  benefit: Benefit,
  shoppingCart: ShoppingCart<AdditionalInfo> | null,
  uid: string,
  anonymous: boolean
) => {
  const { benefitId, type } = benefit;
  const { shippingCost = null } = shoppingCart ?? {};

  try {
    if (
      type === "ALTER_DELIVERY" ||
      type === "DISCOUNT_PERCENTAGE" ||
      type === "DISCOUNT_FIXED"
    ) {
      await removeBenefit({
        benefitId,
        shippingCost,
        apiURL: API_URL,
        accountId: ACCOUNT_ID,
        customerId: uid,
        anonymous
      });
    }

    if (type === "PRODUCT") {
      const storeList = getStoreListUtility(shoppingCart);
      const productList = getProductsUtility(storeList);

      const productBenefit = productList.find(
        product => product.benefitId === benefitId
      );

      if (productBenefit) {
        await removeBenefit({
          apiURL: API_URL,
          benefitId,
          shippingCost,
          product: productBenefit,
          productConfig: {
            store,
            accountId: ACCOUNT_ID,
            customerId: uid,
            anonymous
          },
          accountId: ACCOUNT_ID,
          customerId: uid,
          anonymous
        });
      }
    }
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Function to validate the rules of the benefit (day type)
 *
 * @param {Benefit} benefit The benefit to be applied
 */
const validateBenefitRules = (benefit?: Benefit): BenefitValidationStatus => {
  if (!benefit) {
    return {
      status: false,
      reason: "No se encontró beneficio para aplicar"
    };
  }

  const { rules } = benefit;

  if (!rules) {
    return {
      status: true,
      reason: "No rules to check"
    };
  }

  // TODO: validate all types of rules
  // Only for rules of type "DAYS"
  const daysRules = rules.filter(rule => rule.type === "DAYS");

  if (!daysRules.length) {
    return {
      status: true,
      reason: "No DAYS rules to check"
    };
  }

  const isDayValid = daysRules.some(
    rule => Number(rule.days === "7" ? "0" : rule.days) === dayjs().get("day")
  );

  if (isDayValid) {
    return {
      status: true,
      reason: ""
    };
  }
  return {
    status: false,
    reason: "Beneficio no puede ser aplicado en este día."
  };
};

export const isValidCatalogueInBenefit = (
  catalogueId: Catalogue["catalogueId"],
  benefit?: Benefit
) => {
  if (!benefit) {
    return {
      isValid: false,
      reason: "No se encontró beneficio para aplicar"
    };
  }
  const { channelId } = benefit;
  if (!channelId) {
    return {
      isValid: true,
      reason: ""
    };
  }
  if (channelId === Number(catalogueId)) {
    return {
      isValid: true,
      reason: ""
    };
  }
  const isDelivery = channelId === Number(DELIVERY_CATALOGUE.catalogueId);

  return {
    isValid: false,
    reason: isDelivery
      ? "Este cupón es válido solo para órdenes de domicilio"
      : "Este cupón es válido solo para órdenes de pickup"
  };
};

export const isValidBenefitExpirationDate = (selectedBenefit?: Benefit) => {
  const { expirationDate } = selectedBenefit ?? {};

  const isValidDate = dayjs(expirationDate).isAfter(dayjs());

  return {
    isValid: isValidDate,
    reason: isValidDate ? "" : "El cupón ha expirado"
  };
};

/**
 * Utility that takes a benefitId and a list of benefits and returns the benefit that matches the benefitId
 *
 * @since 3.0.0
 * @param {number} selectedBenefitId - The benefit ID that you want to find in the benefitList.
 * @param {Benefit[]} [benefitList] - This is the list of benefits of the shopping cart.
 * @returns {Benefit} - The benefit object that matches the selectedBenefitId.
 */
export const findBenefitAppliedUtility = (
  selectedBenefitId: number,
  benefitList?: Benefit[]
): Benefit | undefined => {
  const selectedBenefit = benefitList?.find(
    benefit => benefit.benefitId === selectedBenefitId
  );
  return selectedBenefit;
};

/**
 * Utility that validates if the benefit can be applied to the shopping cart.
 *
 * @since 3.0.0
 * @param {Benefit} benefit - Benefit - The benefit that you want to apply.
 * @param {ShoppingCart} isAnonymous - Indicates if the user is anonymous.
 * @param {Function} onError - Function to set the error message.
 */
export const validateApplyBenefitUtility = async (
  benefit: Benefit,
  isAnonymous: boolean,
  uid: string,
  onError?: () => void
) => {
  const { type } = benefit;
  const isRulesValidated = validateBenefitRules(benefit);
  if (!isRulesValidated.status) {
    onError?.();
    return false;
  }

  const shoppingCart = await getShoppingCart({
    shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
    anonymous: isAnonymous,
    accountId: ACCOUNT_ID,
    customerId: uid
  });

  if (type === "ALTER_DELIVERY" && !shoppingCart) {
    onError?.();
    return false;
  }

  const storeList = getStoreListUtility(shoppingCart);

  if (type === "ALTER_DELIVERY" && storeList.length <= 0) {
    onError?.();
    return false;
  }

  const { benefits } = shoppingCart ?? {};
  const selectedBenefit = findBenefitAppliedUtility(
    benefit.benefitId,
    benefits
  );

  if (selectedBenefit) {
    onError?.();
    return false;
  }

  return true;
};

/**
 * Utility that applies a benefit to a shopping cart
 *
 * @since 3.0.0
 * @param {Benefit} benefit - Benefit.
 * @param {ShoppingCart} shoppingCart - ShoppingCart.
 * @param {Function} onError - The function to set the error message.
 * @param {Function} onFinish - The function to call when the operation is finished.
 */
export const applyAlterDeliveryBenefitUtility = async (
  benefit: Benefit,
  shoppingCart: ShoppingCart<AdditionalInfo> | null,
  uid: string,
  onError?: () => void,
  onFinish?: () => void
) => {
  try {
    const headers = await buildArtisnHeaders();
    // In the Artisn SDK, the api/shopping-cart/applyBenefit endpoint is used to apply benefits of type DISCOUNT. While for benefits of type ALTER_DELIVERY no endpoint is used.
    headers.delete("Content-Type");
    const { benefitId } = benefit;
    const { latitude, longitude, shippingCost = null, id } = shoppingCart ?? {};
    const isAnonymous = false;
    if (
      !!benefit &&
      !(await validateApplyBenefitUtility(benefit, isAnonymous, uid, onError))
    ) {
      return;
    }

    await applyBenefit(
      {
        shippingCost,
        latitude,
        longitude,
        benefitId,
        shoppingCartId: id,
        apiURL: API_URL,
        accountId: ACCOUNT_ID,
        anonymous: isAnonymous,
        customerId: uid
      },
      headers
    );
    onFinish?.();
  } catch (e) {
    onError?.();
  }
};
